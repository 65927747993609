import { cocoonInitializer } from '../utils/cocoon'
import fetchOptions from '../utils/fetch-options'

cocoonInitializer($, jQuery)

document.addEventListener 'turbolinks:load', ->
  el = document.getElementById 'the-product-form'
  return false unless el?

  $(el).on 'cocoon:before-insert', (eve, item) ->
    if item[0]
      item[0].innerHTML = item[0].innerHTML.replace(/--CHANGE-ME--/g, new Date().valueOf())
    if eve.target.classList.contains('available_specs_product')
      master_id = document.getElementById('item_subcategory_id').value
      if master_id?
        victim = '#' + item[0].querySelector('.spec_types').id
        fetchOptions "/subcategories/#{master_id}.json", {
          params:
            spec_types: 1
        }, [victim]
    # if eve.target.classList.contains('product_inventories_p')
    #   master_id = document.getElementById('item_subcategory_type_id').value
    #   if master_id?
    #     victim = '#' + item[0].querySelector('.clothing_size_option').id
    #     fetchOptions "/subcategory_types/#{master_id}.json", {
    #       params:
    #         clothing_sizes: 1
    #     }, [victim]
